<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo />
        <!-- End Header Area -->
        <!-- Start 404 Page  -->
        <div class="error-page-inner">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="inner">
                            <h1 class="heading-title">404!</h1>
                            <h3 class="">Page not found</h3>
                            <p>The page you were looking for could not be found.</p>
                            <div class="error-button">
                                <router-link class="rn-button-style--2 btn_solid" to="/">Back To Homepage</router-link>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- End 404 Page  -->
        <FooterTwo />
    </div>
</template>

<script>
  import HeaderOnePageTwo from "../components/header/HeaderOnePageTwo";
  import FooterTwo from "../components/footer/FooterTwo";

  export default {
    components: {
      HeaderOnePageTwo,
      FooterTwo,
    },
    data() {
      return {};
    },
  };
</script>
