import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";

Vue.use(VueRouter);

const routes = [

    {
        path: "/",
        name: "Invitation",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/Invitation.vue"),
    },
    {
        path: "/travel",
        name: "Travel",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/Travel.vue"),
    },
    {
        path: "/accommodation",
        name: "Accommodation",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/Accommodation.vue"),
    },
    {
        path: "/things",
        name: "Things To Do",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/Things.vue"),
    },
    {
        path: "/shuttle",
        name: "Shuttle Bus",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/Shuttle.vue"),
    },
    {
        path: "/schedule",
        name: "Schedule",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/Schedule.vue"),
    },
    {
        path: "/rsvp",
        name: "RSVP",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/Invitation.vue"),
    },
    {
        path: "/management",
        name: "Management",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/Management.vue"),
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/Login.vue"),
    },
    {
        path: "/shuttleTo",
        name: "Shuttle Bus",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/ShuttleTo.vue"),
    },
        {
        path: "/shuttleFrom",
        name: "Shuttle Bus",
        meta: {
            title: "Jess + Josh",
        },
        component: () => import("../views/wedding/ShuttleFrom.vue"),
    },
  {
    path: "*",
    component: Missing,
    meta: {
      title: "404",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
