<template>
    <div>
        <v-navigation-drawer class="hidden-md-and-up header-one-page-nav-drawer"
                             v-model="drawer"
                             fixed
                             width="320">
            <v-list-item class="pa-5">
                <div class="logo">
                    <img src="../../assets/images/logo/logo-symbol-dark.png"
                         alt="Creative Agency Logo" />
                </div>
                <v-spacer></v-spacer>
                <v-btn class="close-icon"
                       icon
                       @click="drawer = !drawer"
                       v-html="iconSvg(closeIcon)">
                </v-btn>
            </v-list-item>

            <scrollactive active-class="v-btn--active"
                          bezier-easing-value=".5,0,.35,1"
                          :offset="71">
                <v-list>
                    <v-list-item :ripple="false"
                                 v-for="item in mobileItems"
                                 :key="item.title"
                                 :to="item.to"
                                 link
                                 class="scrollactive-item">
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- End mobile menu sidebar item list -->
                </v-list>
            </scrollactive>
        </v-navigation-drawer>
        <!-- End sidebar mobile menu -->

        <v-app-bar fixed
                   elevate-on-scroll
                   class="header-one-page-nav header-one-page-nav-two">
            <!-- End brand logo -->

            <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto ml--35">
                <scrollactive active-class="v-btn--active"
                              bezier-easing-value=".5,0,.35,1"
                              :offset="71">
                    <v-btn v-for="item in items"
                           :key="item.title"
                           :to="item.to"
                           link
                           :ripple="false"
                           text
                           :class="currentRouteName === 'item.title' ? 'v-btn--active' : ''">{{ item.title }}</v-btn>
                </scrollactive>
            </v-toolbar-items>
            <!-- End header menu item -->
            <v-spacer></v-spacer>
            <v-btn class="underline pa-0 hidden-md-and-up"
                   @click="drawer = !drawer">
                menu
            </v-btn>
            <!-- End mobile menu icon -->
        </v-app-bar>
        <!-- End top header navbar -->
    </div>
</template>

<script>
    import feather from "feather-icons";
    import socialTwo from "../social/SocialTwo";
    export default {
        components: {
            socialTwo,
        },
        data: () => ({
            drawer: false,
            items: [
                { title: "Invitation", to: "/" },
                { title: "Shuttle Bus", to: "/shuttle" },
                { title: "Schedule", to: "/schedule" },
                { title: "Travel", to: "/travel" },
                { title: "Accommodation", to: "/accommodation" },
                { title: "Things To Do", to: "/things" },
                //{ title: "Schedule", to: "/schedule" },

            ],
            mobileItems: [
                { title: "Invitation", to: "/" },
                { title: "Shuttle Bus", to: "/shuttle" },
                { title: "Schedule", to: "/schedule" },
                { title: "Travel", to: "/travel" },
                { title: "Accommodation", to: "/accommodation" },
                { title: "Things To Do", to: "/things" },

            ],
            icon: "menu",
            closeIcon: "x",
        }),
        methods: {
            iconSvg(icon) {
                return feather.icons[icon].toSvg();
            },
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        }
    };
</script>

<style lang="scss">
    .underline {
        text-decoration:underline !important;
    }
</style>
