<template>
    <div class="footer-style-2 ptb--30 bg_image">
        <div class="wrapper plr--50 plr_sm--20">
            <v-row align="center">
                <v-col lg="4" md="4" sm="6" cols="12">

                </v-col>
                <v-col lg="4" md="4" sm="6" cols="12">
                    <div class="inner">
                        <div class="logo text-center text-sm-left mb_sm--20">
                            <router-link to="/">
                                <img :src="logo" alt="Logo images" />
                            </router-link>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                logo: require("../../assets/images/logo/trydo-symbol-light.png"),
            };
        },
    };
</script>
